import React, { Component, createRef  } from 'react';
import { Sidebar, Header } from '..';
import Moment from 'react-moment';
import { Button, Row, Col, Image } from 'react-bootstrap'
import EndpointConstant from './../../constants/EndpointConstant';
import utils from './../../utils';
import Pagination from '../Pagination/Pagination';
import RoleConstant from '../../constants/RoleConstant';
import ScorecardQuestionariesSearch from './ScorecardQuestionariesSearch';
import EditScorecardQuestionaries from './EditScorecardQuestionaries';
import ConfirmationModal from '../PopupModels/ConfirmationModal';
import MessageConstant from './../../constants/MessageConstant';
import PopupModels from '../PopupModels/PopupModels'
import './ScorecardQuestionaries.css';

class ScorecardQuestionaries extends Component {
  constructor(props) {
    super(props);
    this.state = {
      questionariesDetails: [],
      showpagination: false,
      currentTodos: [],
      addEditShow: false,
      selectedQuestionSet: {},
      isViewMode: false,
      showDeleteConfirmation: false,
      qid: '',
      questionariesSuccessModel: false,
      defaultQuestionaries: [],
      isQuestionNew: false,
      isEditable: false,
      columnWidth: 450,
      displayTexts: {}
      // isNew: false, 
      // isCopy: false
    }
    this.columnRefs = {};
    this.headerRef = createRef();
    this.onChangePage = this.onChangePage.bind(this);
    this.hideViewDetails = this.hideViewDetails.bind(this);
    this.searchResult = this.searchResult.bind(this);
    this.handleMouseDown = this.handleMouseDown.bind(this);
    this.handleMouseMove = this.handleMouseMove.bind(this);
    this.handleMouseUp = this.handleMouseUp.bind(this);
    this.calculateCharLimit = this.calculateCharLimit.bind(this);

  }
  componentDidMount() {
    this.getQuestionariesList();
    window.addEventListener('mouseup', this.handleMouseUp);
  }

  componentWillUnmount() {
    window.removeEventListener('mouseup', this.handleMouseUp);
  }

  getQuestionariesList() {
    let data = {};
    let self = this;
    utils.makeRequest(this, EndpointConstant.SCORECARD_QUESTIONARIE_LIST, data, function (result) {
      utils.hideLoader();
      if (result.error === false) {
        self.loading = false;
        const questions = result.Questionaries;
        self.setState({
          questionariesDetails: questions,
          defaultQuestionaries: questions
        }, () => self.setState({ showpagination: !!result.Questionaries.length }));
      }
    })
  }

  onChangePage(currentTodos) {
    this.setState({ currentTodos: currentTodos }, () => { this.setState({ showpagination: true }) })
  }

  add3Dots(string, limit) {
    var dots = "...";
    if (string && string.length > limit) {
      string = string.substring(0, limit) + dots;
    }
    return string;
  }

  handleMouseDown(event, index) {
    if (event.target.classList.contains('resizable')) {
      this.resizingIndex = index;
      window.addEventListener('mousemove', this.handleMouseMove);
    }
  }
  handleMouseMove(event) {
    if (this.resizingIndex !== null) {
      const minWidth = 100; // Set your minimum width here
      const newWidth = Math.max(minWidth, event.clientX - this.columnRefs[0]?.getBoundingClientRect().left || this.headerRef[0]?.getBoundingClientRect().left);
      const charLimit = this.calculateCharLimit(newWidth);
      const displayTexts = { ...this.state.displayTexts };
      displayTexts[this.resizingIndex] = this.add3Dots(this.state.questionariesDetails[this.resizingIndex]?.questionsetName, charLimit);
      this.setState({ columnWidth: newWidth, displayTexts });
      if (this.headerRef.current) {
        this.headerRef.current.style.width = `${newWidth}px`;
      }
    }
  }

  handleMouseUp() {
    this.resizingIndex = null;
    window.removeEventListener('mousemove', this.handleMouseMove);
  }

  calculateCharLimit(width) {
    // Assuming an average character width of 8 pixels
    return Math.floor(width / 8);
  }

  viewQuestionarieDetails(questionDetails) {
    this.setState({ addEditShow: true, selectedQuestionSet: questionDetails, isViewMode: true, isQuestionNew: true, isEditable: true })
  }

  editQuestionaries(questionDetails) {
    this.setState({ addEditShow: true, selectedQuestionSet: questionDetails, isViewMode: false, isQuestionNew: true, isEditable: true })
  }

  // copyQuestionaries(questionDetails) {
  //   this.setState({ addEditShow: true, selectedQuestionSet: questionDetails, isViewMode: false, isQuestionNew: false, isEditable: false, isNew: true, isCopy: true })
  // }


  deleteQuestionaries(questionSet) {
    this.setState({ qid: questionSet.id, showDeleteConfirmation: true })
  }

  handleDeleteQuestion(qid) {
    let data = { id: qid };
    let self = this;
    utils.makeRequest(this, EndpointConstant.DELETE_SCORECARD_QUESTIONARIE, data, function (result) {
      if (result.error === false) {
        self.loading = false;
        const questions = result.Questionaries;
        self.setState({
          questionariesDetails: questions,
          defaultQuestionaries: questions,
          showDeleteConfirmation: false,
          questionariesSuccessModel: true

        }, () => self.setState({ showpagination: !!result.Questionaries.length }));
      }
    })
  }

  hideViewDetails() {
    this.setState({ addEditShow: false })
  }

  hideDeletePopup() {
    this.setState({ qid: '', showDeleteConfirmation: false })
  }

  handlePopupClose() {
    this.setState({ questionariesSuccessModel: false })
    // window.location.reload(false)
  }

  searchResult(result) {
    this.setState({ questionariesDetails: result })
  }

  render() {
    // {console.log(this.headerRef)}
    const QuestionarieList = this.state.currentTodos.map((data, index) => {
      const charLimit = this.calculateCharLimit(this.state.columnWidth);
      const displayText = this.state.displayTexts[index] || this.add3Dots(data.questionsetName, charLimit);
      return (        
        <tr key={index}>
         <td
            ref={(el) => (this.columnRefs[index] = el)}
            title={data.questionsetName}
            className="resizable1"
            style={{ width: this.state.columnWidth }}
            onMouseDown={(e) => this.handleMouseDown(e, index)}
          >
            {displayText}
          </td>
          <td>
            <Moment format="MM/D/YYYY">{data.createdAt}</Moment></td>
          <td>{data.createdByName}</td>
          <td>
            <Row>
              <Col  xs lg="4" className='pr-0 pl-2'>             
                <span onClick={() => this.editQuestionaries(data)} className='view-details'>
                  <i className="fa fa-eye"></i>&nbsp;View Details</span>
              </Col>
              <Col  xs lg="4" className='pr-0 pl-2' ><span onClick={() => this.deleteQuestionaries(data)} className='delete-style'>
                <Image src="/Images/ico-delete.png" className='image-padding'></Image>Delete</span>
              </Col> 
            </Row>
          </td>
          {/* <td>
            <Row>
               
            </Row>
          </td> */}
        </tr>
      );
    });
    return (      
      <div>
        <Header />
        <div className="sidebar">
          <Sidebar />
        </div>
        {this.state.addEditShow ? <EditScorecardQuestionaries hideViewDetails={this.hideViewDetails} questionDetails={this.state.selectedQuestionSet} questionSetEditMode={!this.state.isViewMode} scorecardQuestionDetails={this.state.questionariesDetails} isQuestionNew={!this.state.isQuestionNew} isEditable={this.state.isEditable}/> :
          <div className="menu-content">
            <div>
              <ScorecardQuestionariesSearch questionDetails={this.state.defaultQuestionaries} searchResult={this.searchResult} />
              <div>
                <table>
                  <thead>
                    <tr>
                      <th className="resizable" onMouseDown={(e) => this.handleMouseDown(e, 'header')}>Questionset</th>
                      {/* <th>Questionset</th> */}
                      <th>Created Date</th>
                      <th>Created By</th>
                      <th>Action</th>                      
                    </tr>
                  </thead>
                  <tbody>
                    {QuestionarieList}
                  </tbody>
                </table>               
                <Row className='padding-top'>
                  <Col>
                    { // eslint-disable-next-line react/no-string-refs
                      this.state.showpagination ? <Pagination ref="pagination" data={this.state.questionariesDetails} onChangePage={this.onChangePage} /> : ''}
                  </Col>

                  <Col className='right-align padding-top'>
                    <Button className='button-color download-font-size add-scorecard' onClick={() => this.editQuestionaries({})}>Add Question set</Button>
                    {/* <Button className='button-color download-font-size' onClick={this.downloadScorecard}>Download Question set</Button> */}
                  </Col>
                </Row>
              </div>
            </div>
            <PopupModels showPopup={this.state.questionariesSuccessModel} iconFlag="success" message={MessageConstant.DELETE_QUESTIONSET} handleClosePopup={this.handlePopupClose.bind(this)} />
            <ConfirmationModal showModal={this.state.showDeleteConfirmation}
              handleCloseModal={this.hideDeletePopup.bind(this)}
              message={MessageConstant.QUESTIONSET_DELETE_CONFIRMATION}
              navigate={() => { this.handleDeleteQuestion(this.state.qid, this.state.questionDetail) }} />
          </div>}
      </div>
    )
  } 
}

export default ScorecardQuestionaries;